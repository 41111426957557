import React from 'react'

export default function Header(){
    return (
	<>
      	    <a href='/' rel="noopener noreferrer">
		<button>
		    Home
		</button>
	    </a>
      	    <a href='https://www.swingbe.de/imprint/'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Imprint
		</button>
	    </a>
      	    <a href='https://www.swingbe.de/privacy-policy/'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Privacy Policy
		</button>
	    </a>
      	    <a href='https://git.wtf-eg.de/dancesWithCycles/delfi'
	       target="_blank" rel="noopener noreferrer">
		<button>
		    Source
		</button>
	    </a>
	</>
    );
};
