import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import Header from './components/header';
import Home from './pages/home';
import StationProfile from './pages/station-profile';

import packageInfo from '../package.json'
const VERSION = packageInfo.version;

export default function App() {

    return (
	<Router>
	    <Header />
	    <p>
		This website (Version:&nbsp;{VERSION}) dislays a public transport station profile for the provided UIC station code.
	    </ p>
	    <Routes>
		<Route path="/" element={<Home />} />
		<Route path="/:uic" element={<StationProfile />} />
		<Route path="*" element={<Navigate to="/" />} />
	    </Routes>
	</Router>
  );
}
