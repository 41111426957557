import React, { useState } from 'react';

export default function Home() {

    const uicUnavailable = '';

    const [uic, setUic] = useState(uicUnavailable);
    const [uicError, setUicError] = useState('');
    const [isComplete, setIsComplete] = useState(false);
    const handleSubmit = (e) => {
	console.log('handleSubmit() start...');
	e.preventDefault();

        setUicError("");
        if (isNaN(uic)) {
	    console.log('handleSubmit() UIC NOT valid');

            setUicError("Error! Enter valid UIC: ");
	} else {
	    console.log('handleSubmit() UIC is complete');
	    setIsComplete(true);
	}

	console.log('handleSubmit() done.');
    };

    const handleSend = (e) => {
	e.preventDefault();
	console.log("Home:handleSend() uic: " + uic);

	setUic(uicUnavailable);
	setUicError('');
	setIsComplete(false);
	console.log("Home:handleSend() done.");
    };

    const ButtonSend = () => {
	if(isComplete) {
	    console.log('buttonSend() complete');
	    return(
		<div>
		    <label
			htmlFor="button-send"
		    >3. Send: </label>
		    <button
			className ="button-send"
			id="button-send"
			type="button"
			onClick={e => handleSend(e)}
		    >Send</button>
		</div>
	    );
	}else{
	    console.log('buttonSend() NOT complete');
	    return(
		<div>
		    {(!isComplete) ? <label htmlFor="button-send">3.Push Submit before Send! </label> : null}
		    <button type="button" disabled>Send</button>
		</div>
	    );
	}
    };

    return (
	<>
	    Enter UIC station code:
	    <br />
            <form onSubmit={e => handleSubmit(e)}>
		<label htmlFor="input-uic">{uicError ? '1. ' + uicError : '1. UIC: '}</label>
		<input
		    onChange={e => setUic(uic => e.target.value)}
		    type="text"
		    value={uic}
		    placeholder="Enter UIC"
		    required
		    className="input-uic"
		    id="input-uic"
		    name="input-uic"
		/>
		<br />
		<label htmlFor="button-submit">2.Submit: </label>
		<button
		    type="submit"
		    className="button-submit"
		    id="button-submit"
		>
                Submit
            </button>
	    </form>
	    <ButtonSend />
	</>
    );
}
