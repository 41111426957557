import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    getWikidata
} from '../utils/request';

/**
 * @param osmSttn array of object of OSM station
 * @return object
 */
async function fetchWikidata(osmSttn) {
    	const objct = await getWikidata(osmSttn[0].tags.wikidata);
	//console.log('fetchWikidata() objct.type: ' + objct.type);
	const strng = JSON.stringify(objct);
	//console.log('fetchWikidata() strng: ' + strng);
	return objct;
    };

/*destructure props object*/
export default function Wikidata ({ sttn }) {

    const [wikidata, setWikidata] = useState({});

    /*fetch array in a JavaScript function*/
    const fetch = async () => {
	if ((sttn !== undefined || sttn !== null ) && sttn.length > 0) {
	    //console.log('fetch() sttn.length: ' + sttn.length);

	    const objct = await getWikidata(sttn[0].tags.wikidata);
	    //console.log('fetch() objct.type: ' + objct.type);
	    const strng = JSON.stringify(objct);
	    //console.log('fetch() strng: ' + strng);
	    setWikidata((wikidata) => objct);
	} else {
	    console.error('fetch() sttn NOT valid');
	}
    };
    
    useEffect(() => {
	/*effect goes here*/
	fetch();
	/*use an empty dependency array to ensure the hook is running only once*/
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [sttn]);

	return (
	    <>
		<p>Wikidata</p>
		<pre>
		    {JSON.stringify(wikidata, null, '\t')}
		</pre>
	</>
    );
};
Wikidata.propTypes = {
    sttn: PropTypes.array
};
