/**
 * get Wikidata content as string
 * @param unique q Wikidata content identifier
 * @return Wikidata content as string
 */
export async function getWikidata(q) {
    //console.log('request:getWikidata() q: ' + q);
    const address = 'https://www.wikidata.org/w/rest.php/wikibase/v0/entities/items/' + q;
    //console.log('request:getWikidata() address: ' + address)
    const objct = await get(address);
    ////console.log('request:getWikidata() objct: ' + JSON.stringify(objct));
    //const strng = JSON.stringify(objct);
    ////console.log('request:getWikidata() strng: ' + strng);
    //return strng;
    return objct
};

/**
 * http get request
 *
 * @param pth path
 * @return response as JSON object
 */
export async function get(pth) {
    const data = await fetch(pth, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const objct = await data.json();
    return objct;
};

/**
 * http get request for DB API
 *
 * @param pth path to DB API
 * @return response as JSON object
 */
export async function getDB(pth) {
    const data = await fetch(pth, {
        method: 'GET',
        headers: {
	    'DB-Api-Key': '08bb79b8e4c2711852bb48d09b0299c3',
	    'DB-Client-Id': 'a86337b51c0361477219b62df158d5b7',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const objct = await data.json();
    return objct;
};
