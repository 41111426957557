import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { get, getDB } from '../utils/request';

import Wikidata from '../components/wikidata';
import Fasta from '../components/fasta';

export default function StationProfile() {

    // Get the userId param from the URL.
    const { uic } = useParams();

    const [stada, setStada] = useState({});
    const [sttnRry, setSttnRry] = useState([]);
    const [bsRry, setBsRry] = useState([]);
    const [bpRry, setBpRry] = useState([]);
    const [prRry, setPrRry] = useState([]);
    const [shopRry, setShopRry] = useState([]);
    const [taxiRry, setTaxiRry] = useState([]);
    const [vmRry, setVmRry] = useState([]);

    useEffect(() => {

	////console.log('StationProfile:useEffect() uic: ' + uic);

	let address = 'https://apis.deutschebahn.com/db-api-marketplace/apis/station-data/v2/stations?eva=' + uic;
	//console.log('StationProfile:useEffect() address: ' + address);

	//get db stada data
	getDB(address).
	    then(data => {
		setStada((stada) => data);
	    });

	address = 'https://overpass-api.de/api/interpreter?data=[out:json][timeout:60];node[uic_ref=' + uic + '];';
	//console.log('StationProfile:useEffect() address: ' + address);

	//get station array
	get(address + 'node[public_transport=station][railway=station](around:2);out body center qt;')
	    .then(data => {
		setSttnRry((sttnRry) => data.elements);
	    });

    	//get bus stop
	get(address + 'nwr[highway=bus_stop](around:200);out body center qt;')
	    .then(data => {
		setBsRry((bsRry) => data.elements);
	    });

    	//get B+R
	get(address + 'nwr[amenity=bicycle_parking](around:200);out body center qt;')
	    .then(data => {
		setBpRry((bpRry) => data.elements);
	    });

    	//get P+R
	get(address + 'nwr["park_ride"!="no"]["park_ride"](around:200);out body center qt;')
	    .then(data => {
		setPrRry((prRry => data.elements));
	    });

    	//get shop
	get(address + 'nwr[shop=ticket]["tickets:public_transport"!=no](around:500);out body center qt;')
	    .then(data => {
		setShopRry((shopRry) => data.elements);
	    });

    	//get taxi
	get(address + 'nwr[amenity=taxi](around:200);out body center qt;')
	    .then(data => {
		setTaxiRry((taxiRry) => data.elements);
	    });

	//get vending machine
	get(address + 'nwr[amenity=vending_machine][vending=public_transport_tickets](around:40);out body center qt;')
	    .then(data => {
		setVmRry((vmRry) => data.elements);
	    });

}, []);

    return (
	<>
	    {sttnRry.length > 0 ? <h1>{sttnRry[0].tags.official_name}</h1> : null}
	    <p>Departures Board</p>
	    <ul>
		<li key='fahrplaner'>
		    <a href={'https://fahrplaner.vbn.de/mct/views/monitor/index.html?cfgFile=a21hTCkgcxEVQKqFv77q_1637321028345&station=' + uic + '&productOrder=%5b%5d'} target="_blank" rel="noopener noreferrer">
			Fahrplaner
		    </a>
		</li>
	    </ul>
	    <p>B+R</p>
	    <ul>
		{
		    bpRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name},
				   Capacity: {value.tags.capacity},
				   Covered: {value.tags.covered},
				   Fee: {value.tags.fee},
				   Operator: {value.tags.operator}
			       </li>;
		    })
		}
	    </ul>
	    <p>Bus Stop</p>
	    <ul>
		{
		    bsRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name},
				   Operator: {value.tags.operator},
				   Network: {value.tags.network},
				   Description: {value.tags.description}
			       </li>;
		    })
		}
	    </ul>
	    <p>Links</p>
	    {sttnRry.length > 0 ? <ul>
				      <li key='osm'>
					  <a href={'https://www.openstreetmap.org/node/' + sttnRry[0].id} target="_blank" rel="noopener noreferrer">
					      OSM
					  </a>
				      </li>
				      <li key='wikidata'>
					  <a href={'https://www.wikidata.org/wiki/' + sttnRry[0].tags.wikidata} target="_blank" rel="noopener noreferrer">
					      Wikidata
					  </a>
				      </li>
				      <li key='wikipedia'>
					  <a href={'https://de.wikipedia.org/wiki/' + sttnRry[0].tags.wikipedia} target="_blank" rel="noopener noreferrer">
					      Wikipedia
					  </a>
				      </li>
				  </ul> : null}
	    <p>OSM</p>
	    {sttnRry.length > 0 ? <pre>{JSON.stringify(sttnRry[0], null, '\t')}</pre> : null}
	    <p>P+R</p>
	    <ul>
		{
		    prRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name},
				   Capacity: {value.tags.capacity},
				   Fee: {value.tags.fee},
				   Website: <a href={value.tags.website}>
						{value.tags.website}
					    </a>
			       </li>;
		    })
		}
	    </ul>
	    <p>Station Data&nbsp;
		<a href='https://developers.deutschebahn.com/db-api-marketplace/apis/product/stada' target="_blank" rel="noopener noreferrer">
		    (DB StaDa)
		</a>
	    </p>
	    <pre>
		{JSON.stringify(stada, null, '\t')}
	    </pre>
	    <Fasta sttn={stada} />
	    <p>Station Plan (
		<a href='https://daten.zvbn.de/ssb/locale/en_GB/legend_en_GB.pdf' target="_blank" rel="noopener noreferrer">
		    Key
		</a>
		)<br/>
		<a href={'https://daten.zvbn.de/ssb/plaene/' + uic + '_orig.jpg'}>
		<img
		    src={'https://daten.zvbn.de/ssb/plaene/' + uic + '_orig.jpg'}
		    alt='Station Plan'
		    height='420'
		/>
		</a>

	    </p>
	    <p>Taxi Stop</p>
	    <ul>
		{
		    taxiRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name}
			       </li>;
		    })
		}
	    </ul>
	    <p>Ticket Shop</p>
	    <ul>
		{
		    shopRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name},
				   Opening Hours: {value.tags.opening_hours}
			       </li>;
		    })
		}
	    </ul>
	    <p>Vending Machine</p>
	    <ul>
		{
		    vmRry.map((value, index) => {
			return <li key={index}>
				   Name: {value.tags.name},
				   Brand: {value.tags.brand},
				   Operator: {value.tags.operator}
			       </li>;
		    })
		}
	    </ul>
	    <Wikidata sttn={sttnRry} />
	</>
    );
}
