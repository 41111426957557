import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    getDB
} from '../utils/request';

/**
 * @param dbStdSttn object from DB Stada API
 * @return object DB Fasta as JSON object
 */
async function fetchFasta(dbStdSttn) {
    const no = dbStdSttn.result[0].number;
    //console.log('fetchFasta() no: ' + no);
    const address = 'https://apis.deutschebahn.com/db-api-marketplace/apis/fasta/v2/stations/' + no;
    //console.log('fetchFasta() address: ' + address);    
    const objct = await getDB(address);
    const strng = JSON.stringify(objct);
    //console.log('fetchFasta() strng: ' + strng);
    return objct;
    };

/*destructure props object*/
export default function Fasta ({ sttn }) {

    const [fastaSttn, setFastaSttn] = useState({});

    /*fetch array in a JavaScript function*/
    const fetch = async () => {
	if ( Object.keys(sttn).length !== 0 ) {
	    //console.log('Fasta:fetch()  sttn: ' + JSON.stringify(sttn));
	    const rslt = sttn.result;
	    if ((rslt !== undefined || rslt !== null ) && rslt.length > 0) {
		//console.log('Fasta:fetch()  rslt.lngth: ' + rslt.length);
		const objct = await fetchFasta(sttn);
		const strng = JSON.stringify(objct);
		//console.log('Fasta:fetch() strng: ' + strng);
		setFastaSttn((fastaSttn) => objct);
	    } else {
		console.error('fetch() rslt NOT valid');
	    }
	} else {
	    console.error('fetch() sttn NOT valid');
	}
    };
    
    useEffect(() => {
	/*effect goes here*/
	fetch();
	/*use an empty dependency array to ensure the hook is running only once*/
	/*TODO study dependency array: https://reactjs.org/docs/hooks-effect.html*/
    }, [sttn]);

	return (
	    <>
		<p>Station Facilities&nbsp;
		    <a href='https://developers.deutschebahn.com/db-api-marketplace/apis/product/fasta' target="_blank" rel="noopener noreferrer">
			(DB FaSta)
		    </a>
		</p>
		<pre>
		    {JSON.stringify(fastaSttn, null, '\t')}
		</pre>
	</>
    );
};
Fasta.propTypes = {
    sttn: PropTypes.object
};
